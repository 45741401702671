import ErrorGroup from '../../types/errors/ErrorGroup';

const promiseAllRequests = async (requests, throwOnError = true) => {
    const results = await Promise.all(
        requests.map(async (req) => {
            try {
                const res = await req;
                return res;
            } catch (err) {
                return err;
            }
        })
    );
    const errors = results.filter((r) => r instanceof Error);
    if (throwOnError && errors.length > 0) {
        throw new ErrorGroup(errors, `Error in promiseAllRequests: ${errors.length} of ${results.length} requests failed.`);
    }
    return results;
};

export default promiseAllRequests;
